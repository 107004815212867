table {
	width: 100%;
	border: 0;
	color: $color-brand-typography;

	> thead,
	> tbody,
	> tfoot {
		> tr {
			padding: 0;
			text-align: left;
			border: 0;
			font-weight: normal;
			color: $color-brand-typography;

			> td,
			> th {
				padding: 0.5rem 0.5rem;
				text-align: left;
				border: 0;
				font-weight: normal;
				vertical-align: middle !important;
				color: $color-brand-typography;
				border-bottom: 2px solid $color-brand-botticelli;
				text-align: left !important;
				background-color: transparent;

				&:first-child {
					padding-left: 0;
				}

				div {
					padding: 0 !important;
					margin: 0 !important;
				}

				select {
					width: 100%;
				}
			}
		}
	}

	> thead > tr > th,
	> thead > tr > td,
	.sub-header > td {
		font-weight: bold;
		vertical-align: top !important;
	}

	> tfoot > tr > td {
		font-size: 0.9em;
		border-bottom: 0;
	}

	h1, h2, h3, h4, h5 {
		@extend h3;
		margin: 0;
		font-size: 1em;
	}

	p {
		@extend body;
		margin: 0;
	}

	.btn {
		padding: 0.25rem 1.5rem;
		font-size: 0.9em;
	}
}

.table-nested {
	margin: 0;

	> tbody > tr:last-child > td {
		border-bottom: 0;
		padding-bottom: 0;
	}
}

.table-responsive {
	overflow-x: auto;
	min-height: 0.01%;

	@media screen and (max-width: $screen-xs-max) {
		width: 100%;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		> table {
			margin-bottom: 0;
			min-width: $screen-sm;
		}

		&.no-min-width {
			> table {
				min-width: auto;
			}
		}
	}
}

.tablesorter {
	.header,
	&-header {
		cursor: pointer;
		position: relative;
		padding-right: 1rem !important;

		&:after {
			@extend .fa;
			@extend .fa-sort:before;
			position: absolute;
			right: 0.25rem;
			top: 0.7rem;
		}
	}

	.headerSortUp,
	&-headerSortUp,
	&-headerAsc {
		&:after {
			@extend .fa;
			@extend .fa-sort-asc:before;
		}
	}

	.headerSortDown,
	&-headerSortDown,
	&-headerDesc {
		&:after {
			@extend .fa;
			@extend .fa-sort-desc:before;
		}
	}

	thead .sorter-false {
		cursor: default;

		&:after {
			content: none;
		}
	}

	&-processing {
		background-position: center center !important;
		background-repeat: no-repeat !important;
		background-image: url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=') !important;
	}

	&-filter-row .disabled {
		cursor: not-allowed;
	}

	&-filter-row.hideme td {
		cursor: pointer;
	}

	&-filter-row.hideme .tablesorter-filter {
		height: 1px;
		min-height: 0;
		border: 0;
		padding: 0;
		margin: 0;
		opacity: 0;
	}

	.filtered {
		display: none;
	}

	&-errorRow td {
		color: $brand-danger;
		cursor: pointer;
	}
}
