/* Home Tiles */

.feature-blocks {
	margin-top: 1.5384615rem;

	@media (min-width: $screen-md) {
		margin-top: 1.5rem;
	}

	&-row {
		margin-top: $grid-gutter-width*1;
		margin-bottom: $grid-gutter-width*0.75;

		@media (min-width: $screen-md) {
			margin-top: $grid-gutter-width*2;
			margin-bottom: $grid-gutter-width;
		}

		& > div {
			@media (min-width: $screen-sm) {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				align-items: flex-start;
			}
		}

		.col-sm-4 {
			@media (max-width: $screen-xs-max) {
				width: 100%;
			}
		}
	}
}

.feature-block {
	&-content {
		position: relative;
		margin-bottom: $grid-gutter-width/2;
		padding-top: 80%;
		overflow: hidden;

		@media (min-width: $screen-md) {
			margin-bottom: $grid-gutter-width;
		}

		a {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-bottom: none;
			line-height: 1.5384615rem;

			&:hover,
			&:focus {
				text-decoration: none;
			}

			@media (min-width: $screen-md) {
				line-height: 1.5rem;
			}
		}
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-bar {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 2.75rem 0.75rem 0.75rem;
		font-weight: 900;
		background-color: rgba($color-bg-dark-blue, 0.3);
		transition: background .25s ease-in-out;

		&:hover {
			background-color: rgba($color-bg-dark-blue, 0.63);
		}

		@media (min-width: $screen-sm) {
			padding: 0.75rem 0.75rem 0.75rem;
		}

		@media (min-width: $screen-md) {
			padding: 1.5rem 0.75rem 0.75rem;
		}

		@media (min-width: $screen-lg) {
			padding: 3rem 0.75rem 0.75rem;
		}
	}
}

.feature-title {
	@extend .h3;
	margin: 0;
	overflow: hidden;
	color: #fff;
	text-transform: uppercase;
	text-overflow: ellipsis;

	@media (max-width: $screen-xs-max) {
		font-size: 2.1153846rem;
	    line-height: 2.4rem;
	}
}

/* IPO Tile */
.feature-block-content.ipo-tile {
	background-color: $color-brand-java;

	a {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0.75rem;
		font-weight: 900;
		color: white;
		background-color: transparent;
		transition: background .25s ease-in-out;
		text-transform: uppercase;
		text-decoration-color: transparent;

		&:hover {
			background-color: rgba($color-bg-dark-blue, 0.23);
			border: none;
		}

		.small {
			font-size: 13px;
			line-height: 1.5;
			margin-top: 0;
			margin-bottom: 0
		}

		.date {
			font-size: 13px;
			line-height: 1;
			text-align: center;
		}

		.ipo {
			margin-top: 0rem;
    		margin-bottom: 0rem;
			text-align: center;
		}

		.lower-box, .link-box {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 0.8em;
		}

		.lower-box {font-size: 0.8em;}

		.title {
			@extend .h3;
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			@media (max-width: $screen-xs-max) {
				font-size: 2.1153846rem;
			    line-height: 2.4rem;
			}
		}

		@media (min-width: $screen-sm) and (max-width: $screen-md-max) { 		
			.small {display: none;}
		}
/*
		@media (min-width: $screen-sm) {
			font-size: 0.9em;
			line-height: 1.1em;
		}

		@media (min-width: $screen-md) {
			font-size: 0.9em;
			line-height: 1.1em;

		}

		@media (min-width: $screen-lg) {
			font-size: 1.1em;
			line-height: 1.25em;
		}*/
	}
}