.hero-banner {
	color: white;
	height: 456px;
	position: relative;
	background-color: $color-brand-regal;

	&.subpage {
		height: 336px;
		text-align: center;
		
		.container.subpage {
			margin-top: 0;
			margin-bottom: 0;
		}

		@media (max-width: $screen-xs-max) {
			height: auto;
			min-height: 336px;
		}
	}

	&-img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		text-align: left;
		opacity: 0.7;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-cont {
		margin: 0 auto;
		padding: 0;

		.hero-banner-content-holder {
		    position: relative;
    		height: 456px;
    		margin: 0;

    		&.subpage {
				height: 336px;
			}
    	}
	}

	.hero-banner-left {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h1 {
			text-transform: capitalize;
			
			@media (max-width: $screen-xs-max) {
				margin-bottom: 1.5rem;
			}
		}
	}

	&.home-page .hero-banner-left {
		align-items: flex-start;
	}
}