.btn {
	border-width: 2px;
	font-weight: 500;
	padding: 0.5rem 2.5rem;
	font-size: 1.1em;
	text-decoration: none;

	.btn-sm {
		padding: 0.25rem 2rem;
	}

	.btn-lg {
		padding: 0.75rem 3rem;
	}

	&:hover {
		color: $color-brand-regal;
		background-color: #fff;
		border-color: #fff;
	}

	&.btn-primary {
		border-color: $color-brand-regal;
		color: white;

		&:hover {
			color: $color-brand-regal;
			background-color: #fff;
			border-color: $color-brand-regal;
		}
	}
}


