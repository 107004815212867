.breadcrumb-nav {
	color: #fff;
	font-size: 0.75rem;
	text-transform: uppercase;
	position: relative;
	height: 0;
	top: 1.5rem;
	z-index: 1;

	@media (max-width: $screen-xs-max) {
		top: 0.75rem;
	}
}

.breadcrumb {
	margin: 0;
	padding: 0;
	font-weight: bold;

	li {
		display: inline;
		list-style-type: none;
	}

	a {
		color: #fff;
		background-color: transparent;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			background-color: transparent;
		}
	}

	> li + li:before {
		padding: 0 0.75rem;
		color: #fff;
		content: "\203A";
		font-size: 1rem;
	}
}