/* secutiry table */

.securities-table {
	font-weight: bold;
	padding: 1rem 0;
	border-top: 2px solid $color-brand-botticelli;
	border-bottom: 2px solid $color-brand-botticelli;
	margin: 2rem 0;

	ul {
		display: flex;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 1.5em;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			padding: 0 2rem 1rem 0;
			flex-basis: 50%;

			@media (min-width: $screen-sm) {
				flex-basis: 25%;
			}

			@media (min-width: $screen-md) {
				flex-basis: 20%;
			}

			b {
				font-size: 0.5em;
			}
		}
	}
}

div#security-header {
	color: $color-brand-typography;
	margin: 0 0 30px 0;
	width: 100%;
}

#security-header a {
	color: $color-brand-typography;
}

.security-brand-logo {
	border-right: 1px solid #EEE;
	float: right;
	overflow: hidden;
	padding: 5px;
	width: 230px;
	height: 60px;
	text-align: center;
	img {
		max-width: 100%;
		max-height: 100%;
	}
}

#security-header-top, #security-header-bottom {
	float: left;
	width: 100%;
}

img.water-mark {
	display: none;
	float: right;
	overflow: hidden;
	padding: 8px;
}

#security-header-top a {
	float: left;
	padding: 6px 15px 10px;
	position: relative;
	top: 4px;
	font-size: 30px;
	&.brand-name {
		font-size: 36px;
		font-weight: bold;
	}
}

#security-header-top a.nsx-code {
	background: none repeat scroll 0 0 #F0EEEF;
	border-left: 1px solid #CCCCCC;
	border-right: 1px solid #CCCCCC;
	color: #000000;
	font-size: 15px;
	padding: 9px 15px 10px;
	position: relative;
	top: 1px;
}

#security-header-bottom {
	background: none repeat scroll 0 0 transparent;
	height: 56px;
	> div {
		float: left;
		letter-spacing: 1px;
		overflow: hidden;
		padding: 8px 20px;
	}
	#actual {}
}

p {
	&#act-1 {
		color: #034579;
		float: left;
		font-size: 28px;
		letter-spacing: 0;
		line-height: 30px;
		margin-left: -5px;
		margin: 0px;
	}
	&#act-2 {
		color: #000000;
		float: right;
		font-size: 11px;
		line-height: 11px;
		margin-top: 4px;
		span {
			padding-left: 5px;
		}
	}
}

#security-header-bottom #high {
	text-align: left;
	width: 122px;
	height: 30px;
}

p {
	&#high-high, &#low-low {
		color: #034579;
		
		letter-spacing: 0;
		margin-left: 4px;
		margin-top: 2px;
	}
	&#high-date, &#low-date {
		color: #999999;
		font-size: 9px;
		letter-spacing: 0;
		/* margin: -5px 0 -23px -11px; */
	}
	&#high-value, &#low-value {
		color: #39A908;
		float: right;
		font-size: 27px;
		left: 8px;
		letter-spacing: 0;
		position: relative;
		top: -26px;
	}
}

#security-header-bottom #low {
	width: 122px;
	height: 30px;
}

p#low-value {
	color: #E14040;
}

#security-header-bottom #volume {}

p {
	&#volume-volume {
		color: #9D9C9D;
		font-size: 14px;
		letter-spacing: 0;
		margin-bottom: -6px;
		margin-top: 2px;
	}
	&#volume-value {
		color: #034579;
		font-family: Verdana,Geneva,sans-serif;
		font-size: 11px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 17px;
		margin-top: 6px;
	}
}

#security-header-bottom #last-trade {
	border-right: medium none;
	letter-spacing: 0;
	width: 170px;
}

p {
	&.last-last {
		color: #9D9C9D;
		float: left;
		font-size: 14px;
		line-height: 11px;
		margin: 3px 0 0 0;
	}
	&#last-date, &#last-hour {
		clear: both;
		color: #034579;
		font-family: Verdana,Geneva,sans-serif;
		font-size: 10px;
		font-weight: bold;
		left: 75px;
		line-height: 13px;
		position: relative;
		text-align: left;
		top: -18px;
	}
}

a.unlinked-anchor:hover {
    text-decoration: none;
}