/* autocomplete */
.ac_results {
	padding: 0px;
	border: 0;
	background-color: white;
	overflow: hidden;
	z-index: 99999;
	box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.5);
	
	@media (max-width: $screen-xs-max) {
		width: auto !important;
		left: $grid-gutter-width/2 !important;
		right: $grid-gutter-width/2 !important;
		max-width: 100%;
	}	

	@media (min-width: $screen-sm) {
		width: 726px !important;
	}

	@media (min-width: $screen-md) {
		width: 350px !important;
	}

	ul {
		width: 100%;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		margin: 0px;
		padding: 0.5rem;
		display: block;
		overflow: hidden;

		&:nth-child(odd){
			background-color: $color-brand-botticelli;
		}
	}
}

.ac_loading {
	background: white url('indicator.gif') right center no-repeat;
}