/* Typesetting Start */
html {
	font-size: 13px;
	line-height: 20px;
}
body {
	font-size: 1rem;
	line-height: 1.5384615rem;
	margin: auto;
	font-family: "Gotham A", "Gotham B", Helvetica, sans-serif;
	color: $color-brand-typography;
	background-color: transparent;
}
h1, .h1 {
	font-size: 3.0769231rem;
	line-height: 3.4615385rem;
	margin-top: 1.5384615rem;
	margin-bottom: 3.076923rem;
}
h2, .h2 {
	font-size: 2.6153846rem;
	line-height: 3.0769231rem;
	margin-top: 1.5384615rem;
	margin-bottom: 1.5384615rem;
}
h3, .h3 {
	font-size: 1.6153846rem;
	line-height: 2rem;
	margin-top: 1.5384615rem;
	margin-bottom: 0rem;
}
h4, .h4 {
	font-size: 1rem;
	line-height: 1.5384615rem;
	margin-top: 1.5384615rem;
	margin-bottom: 0rem;
}
h5, .h5 {
	font-size: 1rem;
	line-height: 1.5384615rem;
	margin-top: 1.5384615rem;
	margin-bottom: 0rem;
}
p, ul, ol, pre, table, blockquote {
	margin-top: 1.5384615rem;
	margin-bottom: 1.5384615rem;
}
ul ul, ol ol, ul ol, ol ul {
	margin-top: 0rem;
	margin-bottom: 0rem;
}

/* Let's make sure all's aligned */
hr, .hr {
	border: 1px solid;
	margin: -1px 0;
}
a, b, i, strong, em, small, code {
	line-height: 0;
}
sub, sup {
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
dl {
	dd {
		margin: 0 0 0.5rem;
	}
}

@media (min-width: $screen-sm) {
	h1, .h1 {
		font-size: 4.2307692rem;
		line-height: 4.6153846rem;
	}
}

@media (min-width: $screen-md) {
	html {
		font-size: 16px;
		line-height: 24px;
	}
	body {
		font-size: 1rem;
		line-height: 1.5rem;
	}
	h1, .h1 {
		font-size: 4.25rem;
		line-height: 4.5rem;
		margin-top: 1.5rem;
		margin-bottom: 3rem;
	}
	h2, .h2 {
		font-size: 2.625rem;
		line-height: 3rem;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
	h3, .h3 {
		font-size: 1.625rem;
		line-height: 2rem;
		margin-top: 1.5rem;
		margin-bottom: 0rem;
	}
	h4, .h4 {
		font-size: 1rem;
		line-height: 1.5rem;
		margin-top: 1.5rem;
		margin-bottom: 0rem;
	}
	h5, .h5 {
		font-size: 1rem;
		line-height: 1.5rem;
		margin-top: 1.5rem;
		margin-bottom: 0rem;
	}
	p, ul, ol, pre, table, blockquote {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}
/* Typesetting End */

td {
	color: $color-brand-typography;
	padding: 3px;
	vertical-align: top;
}

h1, h2, h3, h4, h5 {
	font-weight: bold;
}

a {
	color: $color-brand-regal;
	text-decoration: underline;
	font-weight: 500;
	text-decoration-color: $color-brand-java;

	&:hover, &:focus {
		color: $color-brand-regal;
		text-decoration: underline;
		background-color: $color-brand-java;
	}
}

.content-page-cont,
.content-blue-back,
.subpage {
	a {
		color: $color-brand-regal;
		text-decoration: none;
		font-weight: 500;
		border-bottom: 2px solid $color-brand-java;

		&:hover, &:focus {
			color: $color-brand-regal;
			text-decoration: none;
			background-color: $color-brand-java;
		}
	}
}


/*a:focus,
button:focus,
span:focus {
	outline: 2px solid $color-brand-regal !important;
	outline-offset: 0px !important;

	&:hover,
	&:active {
		outline: none !important;
	}
}*/

.hidden {
	display: none;
}

.text-success {
	color: green;
}

.gridit {
	background: linear-gradient(rgba(0, 119, 179, 0.2) 1px, transparent 1px) left top / 20px 20px;

	@media (min-width: $screen-md) {
		background: linear-gradient(rgba(0, 119, 179, 0.2) 1px, transparent 1px) left top / 24px 24px;
	}
}


@include text-emphasis-variant('.text-primary', $brand-primary);
@include text-emphasis-variant('.text-success', $brand-success);
@include text-emphasis-variant('.text-info', $brand-info);
@include text-emphasis-variant('.text-danger', $brand-danger);