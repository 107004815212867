.topsearch {
	margin: $grid-gutter-width/2 0;
	padding: 0;
	display: flex;
	justify-items: stretch;
	border-radius: 0;

	@media (max-width: $screen-sm-max) {
		width: 100%;
	}

	@media (min-width: $screen-md) {
		margin: 0;
	}

	&-form {
		@media (max-width: $screen-sm-max) {
			width: 100%;
		}

		@media (min-width: $screen-md) {
			position: absolute;
			top: 0;
			left: 0;
			right: $grid-gutter-width*2.5;
			bottom: 0;
			background-color: $color-brand-botticelli;
			z-index: 5;
		}

		form {
			display: flex;
			justify-content: flex-end;
			height: 100%;
			align-items: center;
		}

		.input-group {
			display: flex;
			width: 100%;

			&-input {
				flex-grow: 1;

				input {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			.form-control {
				width: 100%;
			}

			button {
				height: 36px;
				padding: 0.25rem 1.5rem;
				font-size: 0.9em;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			@media (min-width: $screen-md) {
				width: 350px;
			}
		}
	}

	&-toggle {
		@media (max-width: $screen-sm-max) {
			display: none;
		}

		@media (min-width: $screen-md) {
			height: $grid-gutter-width*4;
			background-color: transparent;
			border: 0;
			padding: 0;
			margin: 0;
			border-radius: 0;
			box-shadow: none;
			width: $grid-gutter-width*2;
			outline: none;

			.close {
				display: none;
			}
		}
	}

	@media (min-width: $screen-md) {
		.dropdown-menu {
			display: none;
			height: auto !important;
		}

		&.dropdown.open .dropdown-menu {
			display: block;
			height: auto !important;
		}

		&.dropdown.open .topsearch-toggle {
			.open {
				display: none;
			}

			.close {
				display: inline-block;
			}
		}
	}
}

.blue-back {
	.topsearch-toggle {
		color: white;
	}
}
