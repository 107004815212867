/* horizontal feature boxes */

.horizontal-feature-box-row {
  background-color: $color-brand-botticelli;
  margin: 38px 0 32px 0;
}

.horizontal-feature-box-img img {
  height: 145px;
  width: 175px;
}

.horizontal-feature-box-content {}