.container {
	&.subpage {
		margin: 1.5384615rem auto;

		@media (min-width: $screen-md) {
			margin: 1.5rem auto;
		}
	}
}

.content-blue-back,
.content-blue-back-tight {
	background-color: $color-brand-botticelli;
}

.main-content-blue {
	margin: 0px auto !important;
	padding: 20px 24px;
	background-color: $color-brand-botticelli;
}