/* Primary Colours */
$color-brand-regal: #003865 !default;
$color-brand-hit: #B2BDC5 !default;

/* Secondary Colours */
$color-brand-java: #21E2D0 !default;
$color-brand-picton: #27B7E3 !default;
$color-brand-botticelli: #EFF4F7 !default;
$color-brand-typography: #002A4C !default;

/* Background Colours */
$color-bg-blue: $color-brand-regal;
$color-bg-dark-blue: darken($color-brand-regal, 7.5%);

/* Border */
$color-border: darken($color-brand-botticelli, 20%);

/* Landing Pages */
$lp-container-height: 31.5rem;