.nsx-lp {
	margin-top: $grid-gutter-width*2.5;

	@media (min-width: $screen-md) {
		margin-top: $navbar-height;
	}

	#topnav {
		min-height: $grid-gutter-width*2.5;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		.navbar-nav {
			min-height: $grid-gutter-width*2.5;
			> li {
				min-height: $grid-gutter-width*2.5;
				a {
					min-height: $grid-gutter-width*2.5;
				}
			}
		}
	}

	@media (min-width: $screen-md) {
		#topnav {
			min-height: $navbar-height;
			.navbar-nav {
				min-height: $navbar-height;
				> li {
					min-height: $navbar-height;
					a {
						min-height: $navbar-height;
					}
				}
			}
		}
	}

	&-container {
		min-height: $lp-container-height;
		margin: 0 auto $grid-gutter-width*4;
	}

	&-row {
		min-height: $lp-container-height;
		display: flex;
		flex-direction: column;

		@media (min-width: $screen-sm) {
			flex-direction: row;
			display: flex;
			align-items: stretch;
		}
	}

	&-column {
		&-width {
			&-60 {
				width: 100%;
				
				@media (max-width: $screen-xs-max) {
					order: 2;
				}

				@media (min-width: $screen-sm) {
					width: 50%;
				}

				@media (min-width: $screen-md) {
					width: 60%;
				}
			}

			&-40 {
				width: 100%;

				@media (max-width: $screen-xs-max) {
					order: 2;
				}

				@media (min-width: $screen-sm) {
					width: 50%;
				}

				@media (min-width: $screen-md) {
					width: 40%;
				}
			}
		}

		&-order {
			&-first {
				@media (max-width: $screen-xs-max) {
					order: 1;
				}
			}
		}

		&-text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: $lp-container-height;
			padding: $grid-gutter-width*2;

			@media (min-width: $screen-md) {
				padding: $grid-gutter-width*2 $grid-gutter-width*3;
			}

			@media (min-width: $screen-lg) {
				padding: $grid-gutter-width*3 $grid-gutter-width*4;
			}

			h1, h2, h3, h4, h5, h6, ul, ol, p {
				&:first-child {
					margin-top: 0;
				}
			}

			ul, ol, p {
				margin-bottom: 0;
			}

			ul li, ol li {
				margin-bottom: 0.7692307rem;

				&:last-of-type {
					margin-bottom: 0;
				}

				@media (min-width: $screen-md) {
					margin-bottom: 0.75rem;
				}
			}

			ul.list-unstyled,
			ol.list-unstyled {
				list-style: none;
				padding: 0;
			}
		}

		&-image {
			background-color: $color-brand-regal;
			min-height: $lp-container-height;
			position: relative;
		}

		&-image-grid {
			background-color: $color-brand-regal;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			min-height: $lp-container-height;

			> div {
				position: relative;
				width: 50%;

				@media (min-width: $screen-lg) {
					width: 33.3333333%;
				}
			}
		}

		&-carousel {
			display: flex;
			min-height: $lp-container-height;
			align-items: stretch;
			background-color: $color-brand-regal;

			.nsx-carousel-item {
				background-color: $color-brand-regal;
				min-height: $lp-container-height;
			}
		}

		&-contact {
			text-align: center;
			background-color: $color-brand-regal;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: $lp-container-height;
			padding: $grid-gutter-width*2;

			@media (min-width: $screen-md) {
				padding: $grid-gutter-width*2 $grid-gutter-width*3;
			}

			@media (min-width: $screen-lg) {
				padding: $grid-gutter-width*3 $grid-gutter-width*4;
			}

			svg {
				margin: 0 auto 4rem;
				max-width: 8rem;
				width: 100%;
				fill: white;
				color: white;
			}
		}
	}

	&-image {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.7;
	}
}