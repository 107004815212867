/* footer */

.footer {
	background-color: $color-bg-dark-blue;
	color: #fff;

	a {
		color: #fff;
		border-bottom: transparent;

		&:focus {
			outline: 2px solid $color-brand-picton !important;
			outline-offset: 0px !important;
		}

		background-color: transparent;
		text-decoration: none;

		&:hover {
			border-bottom: 2px solid $color-brand-picton;
			background-color: transparent;
		}

	}
}

.subfooter {
	&-back {
		background-color: $color-brand-regal;
	}

	&-cont {
		padding-top: $grid-gutter-width*1.5;
		padding-bottom: $grid-gutter-width*1.5;

		h3 {
			text-transform: uppercase;
			margin-bottom: 1rem;
			font-size: 1rem;
			
			@media (min-width: $screen-sm) {
				margin-bottom: 1rem;
				margin-top: 0;
			}
		}
	}
}

.sub-footer-links {
	list-style: none;
	margin: 0;
	padding: 0;

	&:before {
		content: '';
		height: 2px;
		background: rgba($color-brand-botticelli, 0.75);
		display: block;

		@media (min-width: $screen-sm) {
			margin-right: $grid-gutter-width;
		}
	}

	li {
		margin: 0.3846154rem 0 0.7692308rem;
		padding: 0;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.footer-bottom {
	&-cont {
		padding-top: 1.5384615rem;
		padding-bottom: 1.5384615rem;
		font-size: 0.7692308rem;
		overflow: hidden;

		@media (min-width: $screen-sm) {
			font-size: 0.75rem;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}
	}
}

.footer-left {
	padding-bottom: 0.7692308rem;

	@media (min-width: $screen-sm) {
		padding-bottom: 0;
	}

	span {
		display: block;

		@media (min-width: $screen-sm) {
			display: inline-block;

			&:last-of-type {
				margin-left: 1.5rem;
			}
		}
	}
}

.footer-right {
	a {
		display: inline;
		margin-right: 1rem;

		&:last-of-type {
			margin-right: 0;
		}
	}

	@media (min-width: $screen-sm) {
		text-align: right;
	}
}

.footer-listing-box {
	position: relative;
	padding-top: 50%;

	@media (min-width: $screen-sm) {
		margin-right: $grid-gutter-width;
		padding-top: 60%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.5;
	}

	.footer-listing-button {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
}