.hero-banner-company {
	&.subpage {
		min-height: 336px;
		height: auto;
		text-align: left;
	}

	h1 {
		margin-top: 4rem;
		margin-bottom: 1rem;
		min-height: 11.75rem;

		span {
			font-size: 0.85em;
			line-height: 1.275em;
			display: inline-block;
		}

		@media (min-width: $screen-md) {
			margin-top: 4rem;
			margin-bottom: 0.5rem;
			min-height: 7rem;

			span {
				font-size: 0.75em;
				line-height: 1.125em;
			}
		}
	}

	.hero-banner-cont {
		.hero-banner-content-holder {
			min-height: 336px;
			height: auto;
		}
	}

	.hero-banner-left {
		display: block;
		position: relative;
	}

	.hero-banner-left.comp-info .comp-info {
		@media (min-width: $screen-sm) {
			width: 100%;
		}

		@media (min-width: $screen-md) {
			overflow: hidden;
		}

		&-large {
			@extend .h1;
			font-weight: bold;
			margin: 0 0 1rem;

			@media (min-width: $screen-md) {
				margin: 0;
				margin-bottom: 1rem;
			}

			span {
				padding: 0 2rem 0 0;
				font-size: 0.85em;

				&:last-of-type {
					padding: 0;
				}

				@media (min-width: $screen-md) {
					font-size: 0.75em;

					&:last-of-type {
						padding: 0 2rem 0 0;
					}
				}
			}
		}

		&-small {
			float: left;

			ul {
				list-style: none;
				margin: 0 0 0.75rem;
				padding: 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				li {
					margin: 0 1.5rem 0 0;
					padding: 0;
					display: inline-block;
					font-weight: 500;
					font-size: 0.8em;

					@media (min-width: $screen-sm) {
						margin: 0 2.25rem 0 0;
					}

					span {
						font-weight: normal;
						font-size: 1rem;
						line-height: 1.1538462rem;
					}

					&:last-of-type {
						margin: 0;
					}
				}
			}
		}

		.red {
			color: $brand-danger;
		}
	}
}