input,
select,
textarea,
.form-control {
	box-shadow: none;
	border: 0;
	border-bottom: 2px solid $color-border;

	&:focus {
		box-shadow: none;
		border-bottom-color: $color-brand-picton;
		outline: none;
	}
}

.form-group {
	input {
		width: 100%;
	}
}