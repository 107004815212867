/* Content feature boxes */

.white-feature-box {
  background-color: #ffffff;
  padding: 15px;
}

.white-feature-box-logos {
  min-height: 140px;
  height: 140px;
  display: inline-table;
  background-color: #ffffff;
  width: 100%;
}

.white-feature-box-shadow {
  -webkit-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.2);
  min-height: 230px;
  height: 230px;
  display: table;
  background-color: #ffffff;
  width: 100%;
}

.feature-inner-headding {
  text-align: center;
  margin: auto auto;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  img {
    margin: auto auto;
    display: block;
  }
}

.white-feature-box-logos .feature-inner-headding {}

.row.tight-row {
  margin-left: -6px;
  margin-right: -6px;
  div[class^="col-"] {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.short-line-blue {
  width: 215px;
  border-bottom: 3px solid $color-brand-regal;
  margin: 24px 0 24px 0;
  display: block;
}

.content-image, .content-image-wide {
  max-width: 100%;
}

.content-box-centred-padded-outer {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.content-box-centred-padded-inner {
  display: table-cell;
  vertical-align: middle;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  li {
    margin-bottom: 8px;
  }
}