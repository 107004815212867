/* content styles */

ul.checkmark {
  padding-left: 22px !important;
  text-indent: -22px;
  list-style: none;
  list-style-position: outside;
  li:before {
    content: '\f00c';
    font-family: FontAwesome;
    padding-right: 6px;
  }
}

.numbered-table.row {
  border-bottom: 2px solid $color-brand-botticelli;

  &:last-of-type {
    margin-bottom: 1.5rem;
  }
}

.large-number {
  @extend .h2;
  font-weight: bold;
  color: $color-brand-regal;
}

a.white-contact-button {
  padding: 15px;
  background-color: #ffffff;
  color: $color-brand-regal;
  
  display: block;
  width: 220px;
  margin: 24px auto;
  text-align: center;
  &:hover {
    text-decoration: none;
    padding: 15px;
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
}

.official-list {
  .rss-link {
    position: absolute;
    top: 35px;
    right: 10px;
  }
}

.img-responsive {
  > img {
    @extend .img-responsive;
  }
}