.calendar-key-due-now{
	padding:2px 4px;
	background:#ff0000;
	color:#ffffff !important;
	font-weight:bold;
}
.calendar-key-due-soon{
	padding:2px 4px;
	background:#008000;
	color:#ffffff !important;
	font-weight:bold;
}