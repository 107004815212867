/*NAVBAR STYLES*/
/*top header css*/
#topnav {
	border: 0;
	margin-bottom: 0;
	background-color: $color-brand-botticelli;
	z-index: 1000;
	outline: 2px solid $color-border;
	transform: translate3d(0,0,0);
	border-radius: 0;

	@media (max-width: $screen-sm-max) {
		min-height: $grid-gutter-width*2.5;
	}

	h4 {
		font-weight: 500;

		* {
			font-weight: 500 !important;
		}
	}

	.toplogo {
		max-width: 272px;
		display: block;
		width: 100%;
		margin-left: $grid-gutter-width/2;
		margin-right: $grid-gutter-width;
		background-color: transparent;
		border-bottom: none;

		&:hover,
		&:focus {
			background-color: transparent;
		}

		@media (min-width: $screen-md) {
			margin-left: 0;
			margin-right: 0;
		}

		#nsx-logo {
			width: 100%;

			.nsx-logo-grey * {
				color: $color-brand-hit;
				fill: $color-brand-hit;
			}

			.nsx-logo-blue * {
				color: $color-brand-regal;
				fill: $color-brand-regal;
			}
		}
	}

	.navbar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: $navbar-height;

		@media (max-width: $screen-sm-max) {
			float: none;
			margin-right: -$grid-gutter-width/2;
			margin-left: -$grid-gutter-width/2;
			height: $grid-gutter-width*3;

			&:before,
			&:after {
				display: none;
			}
		}
	}

	.navbar-toggle {
		border-radius: 0;
		border: 0;
		margin-top: 0;
		margin-bottom: 0;

		@media (max-width: $screen-sm-max) {
			display: block;
		}

		@media (min-width: $screen-md) {
			margin-right: 0;
		}

		&:hover,
		&:focus {
			background-color: transparent;
		}
	}

	.nav > li {
		position: static;
	}

	.navbar-nav {
		@media (max-width: $screen-sm-max) {
			float: none;
			margin: 0 0 $grid-gutter-width/2;
			border-top: 2px solid $color-border;
		}

		@media (min-width: $screen-md) {
			display: flex;
			flex-grow: 1;
			justify-content: center;
		}

		> li {
			float: none;
			position: relative;
			border-bottom: 2px solid $color-border;

			@media (max-width: $screen-sm-max) {
				&.open {
					border: 0;

					.dropdown-menu {
						border-top: 2px solid $color-brand-java;	
					}
				}

				&.active {
					border-bottom: 2px solid $color-brand-picton;
					
					&.open {
						border: 0;

						.dropdown-menu {
							border-top: 2px solid $color-brand-picton;
						}
					}
				}
			}

			@media (min-width: $screen-md) {
				height: $navbar-height;
				position: static;
				border-bottom: 0;

				.dropdown-menu {
					border-top: 0;
				}
			}

			> a,
			> .login-item {
				padding: $grid-gutter-width/2 0;
				display: block;
				background-color: transparent;
				text-decoration: none;
				border-bottom: none;

				&:focus {
					color: $color-brand-typography;
				}

				&:hover {
					background-color: transparent;
				}

				@media (max-width: $screen-sm-max) {
					font-size: 1.5rem;
					font-weight: 500;
				}

				@media (min-width: $screen-md) {
					height: $navbar-height;
					font-weight: 500;
					text-transform: uppercase;
					padding: 0 $grid-gutter-width/2;
					display: flex;
					flex-direction: row;
					align-items: center;
					position: relative;
					cursor: pointer;
					flex-grow: 1;
					text-decoration: none;
				}

				@media (min-width: $screen-lg) {
					padding: 0 $grid-gutter-width;
				}
			}

			&.dropdown > a,
			&.dropdown > .login-item {
				@media (max-width: $screen-sm-max) {
					margin-right: $grid-gutter-width*1.5;
				}
			}

			> button {
				position: absolute;
				width: $grid-gutter-width*1.5;
				background-color: transparent;
				outline: none;
				top: 0;
				right: 0;
				font-size: 1.5rem;
				padding: $grid-gutter-width/2;
				border: 0;

				@media (min-width: $screen-md) {
					display: none;
				}
			}

			&.active > a,
			&:focus > a,
			&:hover > a,
			&:focus > .login-item,
			&:hover > .login-item {
				color: $color-brand-regal;

				@media (min-width: $screen-md) {
					&:after {
						height: 4px;
						background-color: $color-brand-picton;
						content: '';
						position: absolute;
						bottom: $grid-gutter-width;
						left: $grid-gutter-width*0.125;
						right: $grid-gutter-width*0.125;
					}
				}
			}

			&.active > a {
				@media (min-width: $screen-md) {
					&:after {
						background-color: $color-brand-picton;
					}
				}
			}

			.dropdown-menu {
				ul {
					list-style: none;
					padding: 0;
				}

				h4 a {
					text-transform: none;
				}

				@media (max-width: $screen-sm-max) {
					> .container {
						padding: 0;
						width: 100%;

						> .row {
							margin: 0;
							/*display: flex;
							-webkit-flex-wrap: wrap;
							flex-wrap: wrap;
							justify-content: flex-start;*/
						}
					}

					a {
						font-weight: normal;
						background-color: transparent;
						text-decoration: none;
						text-transform: uppercase;

						&:hover,
						&:focus {
							background-color: transparent;
						}
					}
				}
				
				@media (min-width: $screen-md) {
					min-height: 150px;
					color: white;
					display: none;
					padding-bottom: 1rem;				
					background-color: $color-brand-typography;
					color: white;
					position: absolute;
					z-index: 9999;
					top: $navbar-height;
					left: 0;
					right: 0;
					box-shadow: none;
					border: 0;
					height: auto !important;

					ul {
						margin: 0;
						padding: 0;
					}

					li {
						margin: 0;
						padding: 0;
					}

					a {
						color: white;
						font-weight: normal;
						background-color: transparent;
						text-decoration: none;
						text-transform: uppercase;

						&:hover,
						&:focus {
							text-decoration: none;
							border-bottom: 2px solid $color-brand-picton;
							background-color: transparent;
						}
					}
				}
			}

			@media (max-width: $screen-sm-max) {
				ul.sub-nav {
					padding: 0.5rem 0 1rem;

					> li {
						padding: 0.5rem 0;
						font-size: 1.25rem;

						h4 {
							margin: 0.5rem 0;
							font-size: 1.25rem;
						}

						li {
							padding: 0.5rem 0;
						}
					}

					&.vertical {
						padding: 0;

						> li {
							margin: 0.5rem 0 0;

							&:last-child {
								margin: 0.5rem 0 1rem;
							}
						}
					}
				}

				.login-dropdown {
					h4 {
						margin: 1.5rem 0 1rem;
						font-size: 1.25rem;
					}

					.login-form {
						padding: 0;
					}
				}
			}

			@media (min-width: $screen-md) {
				ul.sub-nav {
					border-top: 2px solid rgba($color-brand-botticelli, 0.75);
					padding: 1.5rem 0 1rem;
					overflow: hidden;

					> li {
						padding: 0 2rem 1rem 0;
						float: left;

						&:first-child {
							padding-left: 0;
						}

						li {
							padding: 0.5rem 0;
						}

						ul {
							padding-right: 1rem;
						}
					}

					&.vertical {
						padding: 0;
					}

					&-right {
						display: flex;
						justify-content: flex-end;

						> li {
							float: none;
						}
					}
				}

				.login-dropdown {
					padding: 0 0 1rem;

					&:before {
						background-color: rgba($color-brand-botticelli, 0.75);
						height: 2px;
						content: '';
						display: block;
						margin: 0 $grid-gutter-width/2;
					}

					h4 {
						margin: 1.5rem 0 1rem;
					}

					p {
						margin: 1rem 0 0.5rem;
						font-size: 0.9em;
						text-transform: uppercase;
					}

					form {
						padding-right: 1rem;
					}
				}
			}

			&.dropdown {
				@media (max-width: $screen-sm-max) {
					> button .close {
						display: none;
					}

					&.open {
						> button .open {
							display: none;
						}

						> button .close {
							display: inline-block;
						}
					}
				}
			}

			&.dropdown .dropdown-menu {
				@media (max-width: $screen-sm-max) {
					display: none;
				}
			}

			&.dropdown.open .dropdown-menu {
				display: block;
				height: auto !important;

				@media (min-width: $screen-md) {
					display: none;
				}
			}

			&:focus,
			&:hover {
				> .dropdown-menu {
					@media (min-width: $screen-md) {
						display: block;
					}
				}
			}
		}
	}

	.navbar-collapse {
		@media (max-width: $screen-sm-max) {
			margin-left: -$grid-gutter-width/2;
			margin-right: -$grid-gutter-width/2;

			&.collapse {
				display: none !important;
				height: 0px !important;
				overflow: hidden !important;

				&.in {
					display: block !important;
					height: auto !important;
					overflow: visible !important;
				}
			}
		}

		@media (min-width: $screen-md) {
			display: flex !important;
			min-height: $grid-gutter-width*4;
			max-height: $grid-gutter-width*4;
			padding: 0;

			&.collapse {
				display: flex !important;
			}

			&.topsearch-open {
				position: relative;
			}
		}
	}

	&.blue-back {
		@media (min-width: $screen-md) {
			background-color: $color-brand-typography;
			outline: none;

			.navbar-nav > li > a,
			.navbar-nav > li > .login-item {
				color: white;
			}

			#nsx-logo {
				.nsx-logo-grey * {
					color: white;
					fill: white;
				}

				.nsx-logo-blue * {
					color: white;
					fill: white;
				}
			}
		}
	}

	.navbar-chinese {
		a {
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-weight: bold;
		}
	}



}