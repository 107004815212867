/* paging */

div.pager2 {
  margin: 12px 12px;
}

.pager2 {
  .fa {
    font-size: 14px;
    color: $color-brand-typography;
    cursor: pointer;
  }
  .pagedisplay {
    font-size: 14px;
  }
  select.pagesize {
    font-size: 14px;
    margin-left: 8px;
    padding: 4px;
    option {
      font-size: 14px;
      padding: 30px;
      line-height: 40px;
      height: 40px;
    }
  }
}