.subnav-cont {
	border-bottom: 2px solid $color-border;
	position: relative;
	
	/* &:after {
		content: '';
		position: absolute;
		z-index: 10;
		top: 20px;
		bottom: 20px;
		right: 0;
		width: 20px;
		border-left: 2px solid $color-border;
		background: linear-gradient(to right, rgba(239,244,247,0) 0%,rgba(239,244,247,1) 100%); 
		background-color: $color-brand-botticelli;
	} */

	.side-bar-menu {
		list-style-type: none;
		padding: 0;
		margin-left: -$grid-gutter-width/2;
		margin-right: -$grid-gutter-width/2;
		margin-top: 0;
		margin-bottom: 0;
		height: $navbar-height - ($grid-gutter-width*1.5);
		font-size: 0.8em;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-width: 100%;
		position: relative;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-webkit-overflow-scrolling: touch;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		&:before,
		&:after {
			content: '';
			height: $navbar-height - ($grid-gutter-width);
			flex-basis: 50%;
		}

		> li {
			> a {
				line-height: 1rem;
				color: $color-brand-typography;
				text-transform: uppercase;
				display: block;
				font-weight: 500;
				height: $navbar-height - ($grid-gutter-width*1.5);
				padding: 0 $grid-gutter-width*0.35;
				display: flex;
				flex-direction: row;
				align-items: center;
				position: relative;
				cursor: pointer;
				flex-grow: 1;
				text-decoration: none;
				text-align: center;
				background-color: transparent;
				text-decoration: none;
				border-bottom: none;

				&:hover,
				&:focus {
					background-color: transparent;
				}
			}

			> a.active,
			&:focus > a,
			&:hover > a {
				color: $color-brand-regal;
			
				&:after {
					height: 4px;
					background-color: $color-brand-picton;
					content: '';
					position: absolute;
					bottom: 0;
					left: $grid-gutter-width*0.125;
					right: $grid-gutter-width*0.125;
				}
			}

			&/* :first-child {
				padding-left: $grid-gutter-width/2;
			} */

			&:last-child {
				padding-right: $grid-gutter-width/2;
			}
		}
	}
}