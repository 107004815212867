.nsx-carousel {
	width: 100%;

	&-inner {
		width: 100%;
		height: 100%;
		overflow: hidden;

		> .nsx-carousel-item {
			height: 100%;
			width: 100%;

			&:nth-child(odd) {
				background-color: darken($color-brand-hit, 20%);
			}
		}
	}

	&-controls {
		position: absolute;
		bottom: $grid-gutter-width;
		width: 100%;
		margin: 0;
		margin-left: -50%;
		left: 50%;
		display: flex;
		justify-content: center;
	}

	&-indicators {
		position: relative;
		margin: 0 0.5rem;
		bottom: 0;
		left: 0;
		width: auto;
		height: 2rem;

		li,
		li.active {
			margin: 0.5rem 0.25rem;
			width: 1rem;
			height: 1rem;
			border: 2px solid white;
		}
	}

	&-control-left,
	&-control-right {
		line-height: 2rem;
		display: block;
		width: 2rem;
		height: 2rem;
		margin: 0 0.25rem;
		text-align: center;

		i {
			color: white;
		}
	}
}